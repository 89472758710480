export default {
  userInfoData(state, data) {
    state.userInfo = data
    state.role = data.role
  },
  userInfoSuccess(state, data) {
    state.loading = false
    state.userInfo = data
    state.role = data.role
  },
  loginBegin(state) {
    state.login = true
  },
  loginSuccess(state, data) {
    state.loading = false
    state.login = data
  },

  loginErr(state, err) {
    state.loading = false
    state.error = err
  },

  logoutBegin(state) {
    state.loading = true
  },

  logoutSuccess(state, data) {
    state.loading = false
    state.login = data
  },

  logoutErr(state, err) {
    state.loading = false
    state.error = err
  },

  register(state) {
    state.register = true
  },
  registerSuccess(state) {
    state.loading = false
    state.register = true
  },
  registerErr(state, err) {
    state.loading = false
    state.error = err
    state.register = false
  },
}
