import { DataService } from '../../../config/dataService/dataService'
import mutations from './mutations'

const state = () => ({
  data: [],
  loading: false,
  error: null,
})

const actions = {
  async getDataWalletForUser({ commit }) {
    try {
      const query = await DataService.get('/wallet/for/user')
      if (query.data.status) {
        commit('setPocket', query.data.data)
        return query.data.data
      }
    } catch (err) {
      commit('getDataError', err)
    }
  },
}

export default {
  namespaced: false,
  state,
  actions,
  mutations,
}
