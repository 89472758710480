export default [
  {
    path: '/pocket/deposit',
    name: 'pocket-deposit',
    component: () => import(/* webpackChunkName: "pocket-deposit" */ '@/view/pocket/Index.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/pocket/withdraw',
    name: 'pocket-withdraw',
    component: () => import(/* webpackChunkName: "pocket-withdraw" */ '@/view/pocket/Withdraw.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/pocket/transfer',
    name: 'pocket-transfer',
    component: () => import(/* webpackChunkName: "pocket-transfer" */ '@/view/pocket/Transfer.vue'),
    meta: { requiresAuth: true },
  },
]
