export default [
  {
    path: '/users/customer',
    name: 'users-custerom',
    component: () => import(/* webpackChunkName: "users" */ '../view/users/Index.vue'),
  },
  {
    path: '/users/company',
    name: 'users-company',
    component: () => import(/* webpackChunkName: "users" */ '../view/users/Index.vue'),
  },
]
