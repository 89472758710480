import { DataService } from '../../../../config/dataService/dataService'
import { removeItem, setItem } from '../../../../utility/localStorageControl'
import mutations from './mutations'
import Cookies from 'js-cookie'
import { notification } from 'ant-design-vue'

const state = () => ({
  login: Cookies.get('logedIn'),
  loading: false,
  error: null,
})

const actions = {
  async login({ commit }, state) {
    commit('changeLayoutBegin', true) //loading
    try {
      const query = await DataService.post('/auth/login', {
        email: state.email,
        password: state.password,
      })
      if (query.data.status) {
        setItem('access_token', query.data.access_token)
        notification['success']({
          message: query.data.message,
        })
        Cookies.set('logedIn', true)
        commit('loginBegin')
        setTimeout(() => {
          window.location.reload()
          // commit('changeLayoutErr', false) //loading close
        }, 1000)
        return commit('loginSuccess', true)
      } else {
        commit('changeLayoutErr', false) //loading close
        notification['error']({
          message: query.data.message,
        })
      }
    } catch (err) {
      notification['error']({
        message: err.message,
      })
      commit('changeLayoutErr', false) //loading close
      commit('loginErr', err)
    }
  },
  async logOut({ commit }) {
    try {
      commit('logoutBegin')
      Cookies.remove('logedIn')
      removeItem('access_token')
      commit('logoutSuccess', null)
    } catch (err) {
      commit('logoutErr', err)
    }
  },
  async register({ commit }, state) {
    commit('changeLayoutBegin', true) //loading
    try {
      const query = await DataService.post('/auth/register', state.formState)
      if (query.data.status) {
        notification['success']({
          message: query.data.message,
        })
        commit('changeLayoutErr', false) //loading close
        commit('register', true)
        return commit('registerSuccess', true)
      } else {
        notification['error']({
          message: query.data.message,
        })
        commit('changeLayoutErr', false) //loading close
        commit('registerErr', query.data.message)
      }
    } catch (err) {
      commit('changeLayoutErr', false) //loading close
      commit('registerErr', err)
    }
  },
  async getUserInfo({ commit }) {
    try {
      const query = await DataService.get('/auth/user/info')
      if (query.data.status) {
        commit('userInfoData', query.data.data)
        return commit('userInfoSuccess', query.data.data)
      }
    } catch (err) {
      console.log('err', err)
    }
  },
  async updateUserInfo({ commit }, state) {
    try {
      const query = await DataService.patch('/auth/' + state.id, state)
      if (query.data.status) {
        commit('userInfoData', query.data.data)
        notification['success']({
          message: query.data.message,
        })
        return commit('userInfoSuccess', query.data.data)
      } else {
        notification['error']({
          message: query.data.message,
        })
      }
    } catch (err) {
      notification['error']({
        message: err.message,
      })
      console.log('err', err)
    }
  },
  async updatePassword({ commit }, state) {
    try {
      const query = await DataService.patch('/auth/user/resetPassword/' + state.id, state)
      if (query.data.status) {
        notification['success']({
          message: query.data.message,
        })
        commit('logoutBegin')
        Cookies.remove('logedIn')
        removeItem('access_token')
        commit('logoutSuccess', null)
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      } else {
        notification['error']({
          message: query.data.message,
        })
      }
    } catch (err) {
      notification['error']({
        message: err.message,
      })
      console.log('err', err)
    }
  },
}

export default {
  namespaced: false,
  state,
  actions,
  mutations,
}
